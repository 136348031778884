import request from '@/utils/request'


// 包裹扫描
export function scanning(data) {
  return request({
    method: 'post',
    url: `/auth/pack/scanning`,
    data
  })
}

// 包裹扫描回退
export function scanningback(data) {
  return request({
    method: 'post',
    url: `/auth/pack/scanning/back`,
    data
  })
}

// 分页多条件退货包裹扫描查询
export function findScanningInfoByPage(data) {
  return request({
    method: 'post',
    url: `/auth/pack/findScanningInfoByPage`,
    data
  })
}
